import React from "react";
import "../styles/ContactUs.scss";

import leftD from "../assets/images/right-design.png";
import rightD from "../assets/images/left-design.png";
import phone from "../assets/icons/phone.svg";
import mapPin from "../assets/icons/map-pin.svg";
import indicator from "../assets/icons/indicator.svg";
import telegram from "../assets/icons/telegram2.svg";
import instagram from "../assets/icons/instagram2.svg";
import twitter from "../assets/icons/twitter.svg";
import whatsapp from "../assets/icons/whatsappp2.svg";

import miniLeftD from "../assets/images/mini-left-design.png";
import miniRightD from "../assets/images/mini-right-design.png";

const ContactUsPage = () => {
  return (
    <div className="contact-us-container">
      <div className="main-header">
        <img src={miniLeftD} className="hidden max-[600px]:block" />
        <img src={rightD} className="max-[600px]:hidden" />
        <h1>Contact Us</h1>
        <img src={miniRightD} className="hidden max-[600px]:block" />
        <img src={leftD} className="max-[600px]:hidden" />
      </div>
      <div className="gradient-border4">
        <div className="contact-container">
          <div className="title">
            <span>Get in touch with us</span>{" "}
          </div>
          <div className="content">
            We are always ready to hear your feedback, suggestions, and
            questions. If you need guidance or more information, please feel
            free to reach out to us through the following channels:
          </div>
          <div className="contanct-items">
            <div className="item">
              <div className="flex font-bold text-[1.125rem] items-center justify-between">
                <img className="mx-[0.75rem]" src={indicator} />
                Phone number
                <img className="mx-[0.75rem]" src={indicator} />
              </div>
              <div className="values">
                <span>
                  <img src={phone} alt="phone" /> +902125705959
                </span>
                <span>
                  <img src={phone} alt="phone" /> +905445562282-3
                </span>
              </div>
            </div>
            <div className="item">
              <div className="flex font-bold text-[1.125rem]">
                <img className="mx-[0.75rem]" src={indicator} />
                Addresses
                <img className="mx-[0.75rem]" src={indicator} />
              </div>
              <div className="values">
                <span>
                  <img src={mapPin} alt="map-pin" /> Istanbul, Beylikdüzü
                  Innovıa 1 Karşı
                </span>
                <span>
                  <img src={mapPin} alt="map-pin" /> Istanbul, Beylikdüzü
                  Innovıa 3
                </span>
                <span>
                  <img src={mapPin} alt="map-pin" /> Istanbul, Kapalı çarşı
                </span>
              </div>
            </div>
            <div className="item">
              <div className="flex font-bold text-[1.125rem] items-center justify-between">
                <img className="mx-[0.75rem]" src={indicator} />
                Social media
                <img className="mx-[0.75rem]" src={indicator} />
              </div>
              <div className="social">
                <a href="https://instagram.com/mrdolarfarsi">
                  <img src={instagram} />
                </a>
                <a href="">
                  <img src={telegram} />
                </a>
                <a href="https://wa.me/+905445562283">
                  <img src={whatsapp} />
                </a>
                {/* <a>
                  <img src={twitter} />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage;
