import React, { useEffect, useState } from "react";
import "../styles/ExchangeRate.scss";

import up from "../assets/icons/up.svg";
import down from "../assets/icons/down.svg";
import diagram from "../assets/icons/diagram.svg";
import darkDiagram from "../assets/icons/dark-diagram.svg";
import dropdown from "../assets/icons/dropdown.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import WorldClocks from "../components/WorldClocks";

// Helper function to format time to military (24-hour) format
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

// Helper function to format date as "DD MMM" without year
const formatDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });
};

// Helper function to calculate percentage difference
const calculatePercentageDifference = (currentPrice, previousPrice) => {
  if (previousPrice === 0) return 0;
  return (((currentPrice - previousPrice) / previousPrice) * 100).toFixed(2);
};

const ExchangeRatePage = ({
  currencies,
  cryptoCurrencies,
  tomanCurrencies,
  golds,
  coins,
  noCategories,
  remittances,
  selected,
}) => {
  const [selectedOption, setSelectedOption] = useState("Currency first");
  const [isOpen, setIsOpen] = useState(false);
  const [USDRates, setUSDRates] = useState({});
  const [TRYRates, setTRYRates] = useState({});

  const isToggledFromStore = useSelector((state) => state.isToggled);

  const location = useLocation();
  useEffect(() => {
    if (location.state?.selected) {
      setSelectedOption(location.state.selected);
    }
  }, [location.state]);

  const options = ["Gold first", "Currency first", "Crypto currency first"];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  // Find the currency with the latest `updatedAt`
  const latestCurrency = cryptoCurrencies?.reduce((latest, item) => {
    return new Date(item?.updatedAt) > new Date(latest?.updatedAt)
      ? item
      : latest;
  }, currencies[0]);

  // Fetch data from the API on component mount
  useEffect(() => {
    const fetchUSDRates = async () => {
      try {
        const response = await fetch(
          "https://api.metalpriceapi.com/v1/latest?api_key=be59eb2982af3b77cd60353923939e90&base=USD&currencies=EUR,GBP,AED,CNY,BTC,ETH,USDT,BNB"
        );
        const data = await response.json();
        if (data.success) {
          setUSDRates(data.rates); // Save the rates from the API response
        }
      } catch (error) {
        console.error("Error fetching rates:", error);
      }
    };
    const fetchTRYRates = async () => {
      try {
        const response = await fetch(
          "https://api.metalpriceapi.com/v1/latest?api_key=be59eb2982af3b77cd60353923939e90&base=TRY&currencies=USD,EUR,GBP,AED,CNY,BTC,ETH,USDT,BNB"
        );
        const data = await response.json();
        if (data.success) {
          setTRYRates(data.rates); // Save the rates from the API response
        }
      } catch (error) {
        console.error("Error fetching rates:", error);
      }
    };
    fetchTRYRates();
    fetchUSDRates();
  }, []);

  const renderCurrencyLiraTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Currency
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(currencies) &&
              currencies?.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /TRY</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {TRYRates["TRY" + item.symbol]
                        ?.toFixed(2)
                        .toLocaleString() || item?.TRYBuyPrice?.toLocaleString()}
                    </div>
                    <div className="text">
                      {item.TRYSellPrice?.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={item.TRYBuyPrice > item?.TRYPreBuyPrice ? up : down}
                        alt={
                          item.TRYBuyPrice > item.TRYPreBuyPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item.TRYBuyPrice > item.TRYPreBuyPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          item.TRYBuyPrice,
                          item.TRYPreBuyPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderCurrencyDollarTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Currency
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(currencies) &&
              currencies
                .filter((item) => item.id !== 1)
                .map((item, index) => (
                  <div className="item" key={`${item.name}-${index}`}>
                    <div className="g-3">
                      <div className="title-7">
                        {item.name}
                        <span className=""> /USD</span>
                      </div>
                      {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                    </div>
                    <div className="g-2">
                      <div className="text">
                        {USDRates["USD" + item.symbol]
                          ?.toFixed(2)
                          .toLocaleString() ||
                          item.USDBuyPrice.toLocaleString()}
                      </div>
                      <div className="text">
                        {item.USDSellPrice.toLocaleString()}
                      </div>
                      <div className="text-icon">
                        <img
                          src={
                            item.USDBuyPrice > item.USDPreBuyPrice ? up : down
                          }
                          alt={
                            item.USDBuyPrice > item.USDPreBuyPrice
                              ? "Uptrend"
                              : "Downtrend"
                          }
                        />
                        <div
                          className={`title-9 ${
                            item.USDBuyPrice > item.USDPreBuyPrice
                              ? "green-rgba"
                              : "red-rgba"
                          }`}
                        >
                          {calculatePercentageDifference(
                            item.USDBuyPrice,
                            item.USDPreBuyPrice
                          )}
                          %
                        </div>
                      </div>
                    </div>
                    <img
                      className="ml-2"
                      src={!isToggledFromStore ? darkDiagram : diagram}
                      alt="Trend diagram"
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderCurrencyTomanIstanbulTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Istanbul Currency
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(tomanCurrencies) &&
              tomanCurrencies.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /IRR</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {item.istanbulBuyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {item.istanbulSellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={
                          item.istanbulBuyPrice > item.istanbulPreBuyPrice
                            ? up
                            : down
                        }
                        alt={
                          item.istanbulBuyPrice > item.istanbulPreBuyPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item.istanbulBuyPrice > item.istanbulPreBuyPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          item.istanbulBuyPrice,
                          item.istanbulPreBuyPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderCurrencyTomanTehranTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Tehran Currency
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(tomanCurrencies) &&
              tomanCurrencies.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /IRR</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {item.tehranBuyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {item.tehranSellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={
                          item.tehranBuyPrice > item.tehranPreBuyPrice
                            ? up
                            : down
                        }
                        alt={
                          item.tehranBuyPrice > item.tehranPreBuyPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item.tehranBuyPrice > item.tehranPreBuyPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          item.tehranBuyPrice,
                          item.tehranPreBuyPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderGoldIstanbulTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Istanbul Gold
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(golds) &&
              golds.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /USD</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {item.istanbulBuyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {item.istanbulSellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={
                          item.istanbulBuyPrice > item.istanbulPreBuyPrice
                            ? up
                            : down
                        }
                        alt={
                          item.istanbulBuyPrice > item.istanbulPreBuyPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item.istanbulBuyPrice > item.istanbulPreBuyPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          item.istanbulBuyPrice,
                          item.istanbulPreBuyPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderGoldTehranTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Tehran Gold
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(golds) &&
              golds.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /IRR</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {item.tehranBuyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {item.tehranSellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={
                          item.tehranBuyPrice > item.tehranPreBuyPrice
                            ? up
                            : down
                        }
                        alt={
                          item.tehranBuyPrice > item.tehranPreBuyPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item.tehranBuyPrice > item.tehranPreBuyPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          item.tehranBuyPrice,
                          item.tehranPreBuyPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderCoinTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Tehran Coin
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(coins) &&
              coins.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /IRR</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {item.buyPrice?.toLocaleString()}
                    </div>
                    <div className="text">
                      {item.sellPrice?.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={item.tehranBuyPrice > item.preBuyPrice ? up : down}
                        alt={
                          item.tehranBuyPrice > item.preBuyPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item.buyPrice > item.preBuyPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          item.buyPrice,
                          item.preBuyPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderCryptoTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Crypto
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(cryptoCurrencies) &&
              cryptoCurrencies.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /USD</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {USDRates["USD" + item.symbol]?.toFixed(2) ||
                        item.buyPrice}
                    </div>
                    <div className="text">
                      {item.sellPrice}
                    </div>
                    <div className="text-icon">
                      <img
                        src={item.buyPrice > item.preBuyPrice ? up : down}
                        alt={
                          item.buyPrice > item.preBuyPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item.buyPrice > item.preBuyPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          item.buyPrice,
                          item.preBuyPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderNoCategoryTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Other
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(noCategories) &&
              noCategories.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /USD</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {USDRates["USD" + item.symbol]?.toFixed(2) ||
                        item.buyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {item.sellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={item.buyPrice > item.preBuyPrice ? up : down}
                        alt={
                          item.buyPrice > item.preBuyPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item.buyPrice > item.preBuyPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          item.buyPrice,
                          item.preBuyPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderRemittanceTable = () => (
    <div className="gradient-border3">
      <div className="prices">
        <div className="list-title">
          <div className="title-4">
            <div>
              <div
                className={`table-title`}
                id={`${!isToggledFromStore ? "dark-title" : ""}`}
              >
                Remittance
              </div>
              <div className="g">
                <div className="title-5">
                  {formatDate(latestCurrency?.updatedAt)} -
                </div>
                <div className="title-5">
                  {formatTime(latestCurrency?.updatedAt)}
                </div>
              </div>
            </div>
            <div className="g-2">
              <div className="title-6">Buying</div>
              <div className="title-6">Selling</div>
              <div className="title-6">Difference</div>
            </div>
          </div>
          <div
            className={`list-2 ${
              isToggledFromStore === false ? "black-scroll" : ""
            }`}
          >
            {Array.isArray(remittances) &&
              remittances.map((item, index) => (
                <div className="item" key={`${item.name}-${index}`}>
                  <div className="g-3">
                    <div className="title-7">
                      {item.name}
                      <span className=""> /USD</span>
                    </div>
                    {/* <div className="title-8">{formatTime(item.updatedAt)}</div> */}
                  </div>
                  <div className="g-2">
                    <div className="text">
                      {USDRates["USD" + item.symbol]?.toFixed(2) ||
                        item.buyPrice.toLocaleString()}
                    </div>
                    <div className="text">
                      {item.sellPrice.toLocaleString()}
                    </div>
                    <div className="text-icon">
                      <img
                        src={item.buyPrice > item.preBuyPrice ? up : down}
                        alt={
                          item.buyPrice > item.preBuyPrice
                            ? "Uptrend"
                            : "Downtrend"
                        }
                      />
                      <div
                        className={`title-9 ${
                          item.buyPrice > item.preBuyPrice
                            ? "green-rgba"
                            : "red-rgba"
                        }`}
                      >
                        {calculatePercentageDifference(
                          item.buyPrice,
                          item.preBuyPrice
                        )}
                        %
                      </div>
                    </div>
                  </div>
                  <img
                    className="ml-2"
                    src={!isToggledFromStore ? darkDiagram : diagram}
                    alt="Trend diagram"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="exchange-rate-page">
      <div className="custom-dropdown">
        <div className="gradient-border">
          <div
            className={`dropdown-header ${isOpen ? "open" : ""}`}
            onClick={toggleDropdown}
          >
            <img
              src={dropdown}
              alt="dropdown-background"
              className="dropdown-bg mr-[10px]"
            />
            <span>{selectedOption}</span>
          </div>
        </div>
        {isOpen && (
          <ul
            className={`dropdown-options ${
              !isToggledFromStore ? "dark-drop" : ""
            }`}
          >
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className="dropdown-option"
              >
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="exchange-rate-container">
        {selectedOption === "Gold first" && (
          <>
            {renderCoinTable()}
            {renderGoldIstanbulTable()}
            {/* {renderGoldTehranTable()} */}
            {renderCurrencyTomanIstanbulTable()}
            {renderCurrencyTomanTehranTable()}
            {renderCurrencyLiraTable()}
            {/* {renderCurrencyDollarTable()} */}
            {renderNoCategoryTable()}
            {renderCryptoTable()}
            {renderRemittanceTable()}
          </>
        )}
        {selectedOption === "Currency first" && (
          <>
            {renderCurrencyTomanIstanbulTable()}
            {renderCurrencyTomanTehranTable()}
            {renderCurrencyLiraTable()}
            {/* {renderCurrencyDollarTable()} */}
            {renderCoinTable()}
            {renderGoldIstanbulTable()}
            {/* {renderGoldTehranTable()} */}
            {renderNoCategoryTable()}
            {renderCryptoTable()}
            {renderRemittanceTable()}
          </>
        )}
        {selectedOption === "Crypto currency first" && (
          <>
            {renderCryptoTable()}
            {renderCurrencyTomanIstanbulTable()}
            {renderCurrencyTomanTehranTable()}
            {renderCurrencyLiraTable()}
            {/* {renderCurrencyDollarTable()} */}
            {renderCoinTable()}
            {renderGoldIstanbulTable()}
            {renderNoCategoryTable()}
            {/* {renderGoldTehranTable()} */}
            {renderRemittanceTable()}
          </>
        )}
      </div>
      <WorldClocks />
    </div>
  );
};

export default ExchangeRatePage;
