import "./App.css";

import { Routes, Route } from "react-router-dom";

import AboutUsPage from "./pages/AboutUsPage";
import HomePage from "./pages/HomePage";
import ExchangeRatePage from "./pages/ExchangeRatePage";
import ContactUsPage from "./pages/ContactUsPage";
import Header from "./components/Header";
import Footer from "./components/Footer";

import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";

const getCurrencyName = (symbol_id) => {
  const nameMap = {
    COINBASE_SPOT_BTC_USD: "Bitcoin",
    COINBASE_SPOT_ETH_USD: "Ethereum",
    BINANCEFTSC_PERP_BNB_USD: "BNB",
    COINBASE_SPOT_USDT_USD: "Tether",
    USD_TRY: "US Dollar / Turkish Lira",
    CNY_TRY: "Chinese Yuan / Turkish Lira",
    EUR_TRY: "Euro / Turkish Lira",
    GBP_TRY: "Pound Sterling / Turkish Lira",
    AED_TRY: "Emirati Dirham / Turkish Lira",
    // Add more mappings as necessary
  };

  return nameMap[symbol_id] || null; // Return null for unknown currencies
};

const getCurrencySymbol = (symbol_id) => {
  const symbolMap = {
    COINBASE_SPOT_BTC_USD: "BTC",
    COINBASE_SPOT_ETH_USD: "ETH",
    BINANCEFTSC_PERP_BNB_USD: "BNB",
    COINBASE_SPOT_USDT_USD: "USDT",
    USD_TRY: "USD/TRY",
    CNY_TRY: "CNY/TRY",
    EUR_TRY: "EUR/TRY",
    GBP_TRY: "GBP/TRY",
    AED_TRY: "AED/TRY",
    // Add more mappings as necessary
  };

  return symbolMap[symbol_id] || null; // Return null for unknown symbols
};

const App = () => {
  const { REACT_APP_URL } = process.env;
  const isToggledFromStore = useSelector((state) => state.isToggled);

  // states
  const [currencies, setCurrencies] = useState([]);
  const [cryptoCurrencies, setcryptoCurrencies] = useState([]);
  const [tomanCurrencies, settomanCurrencies] = useState([]);
  const [golds, setGolds] = useState([]);
  const [coins, setCoins] = useState([]);
  const [noCategories, setNoCategories] = useState([]);
  const [remittances, setRemittances] = useState([]);
  const [announcements, setAnnouncements] = useState("");

  useEffect(() => {
    const socket = io(`${REACT_APP_URL}`, {
      transports: ["polling"],
    });

    socket.on("getCurrencies", async (data) => {
      const _data = data.currencies;
      settomanCurrencies(_data);
    });

    socket.on("getCoins", (data) => {
      const _data = data.goldInTomans;
      setGolds(_data);
    });

    socket.on("getAnnouncement", (data) => {
      const _data = data.announcement;
      setAnnouncements(_data);
    });

    const getData = async () => {
      try {
        const currRes = await fetch(`${REACT_APP_URL}/currency`);
        const currData = await currRes?.json();
        setCurrencies(currData.currencies);

        const cryptoCurrRes = await fetch(`${REACT_APP_URL}/currency/crypto`);
        const cryptoCurrData = await cryptoCurrRes?.json();
        setcryptoCurrencies(cryptoCurrData.currencies);

        const tomanCurrRes = await fetch(`${REACT_APP_URL}/currency/toman`);
        const tomanCurrData = await tomanCurrRes?.json();
        settomanCurrencies(tomanCurrData.currencies);

        const goldRes = await fetch(`${REACT_APP_URL}/gold`);
        const goldData = await goldRes?.json();
        setGolds(goldData.goldInTomans);

        const announceRes = await fetch(`${REACT_APP_URL}/announcement`);
        const anounceData = await announceRes?.json();
        setAnnouncements(anounceData.announcements);

        const coinRes = await fetch(`${REACT_APP_URL}/coin`);
        const coinData = await coinRes?.json();
        setCoins(coinData.coins);

        const noCategoryRes = await fetch(`${REACT_APP_URL}/no-category`);
        const noCategoryData = await noCategoryRes?.json();
        setNoCategories(noCategoryData.withoutCategories);

        const remittanceRes = await fetch(`${REACT_APP_URL}/remittance`);
        const remittanceData = await remittanceRes?.json();
        setRemittances(remittanceData.remittances);
      } catch (err) {
        console.log(err);
      }
    };

    getData();
  }, []);

  // console.log(cryptoCurrencies);

  useEffect(() => {
    const socket = new WebSocket("wss://ws.coinapi.io/v1/");

    socket.onopen = () => {
      console.log("Connected to CoinAPI WebSocket");

      // Send subscription message for both crypto and fiat currencies
      const subscriptionMessage = JSON.stringify({
        type: "hello",
        apikey: "6A02CA22-10BA-4106-A919-1F5F1089AE15", // Replace with your CoinAPI key
        heartbeat: true,
        subscribe_data_type: ["trade"],
        subscribe_filter_asset_id: [
          // Crypto currencies
          "BTC/USD",
          "ETH/USD",
          "BNB/USD",
          "USDT/USD",
          // Fiat currencies
          "USD/TRY",
          "CNY/TRY",
          "EUR/TRY",
          "GBP/TRY",
          "AED/TRY",
        ],
      });

      socket.send(subscriptionMessage);
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.type === "trade") {
        const currencyName = getCurrencyName(data.symbol_id);
        const currencySymbol = getCurrencySymbol(data.symbol_id);

        // Only proceed if the currency is known
        if (currencyName && currencySymbol) {
          const newCurrency = {
            id: 1, // Set or handle IDs manually
            name: currencyName,
            symbol: currencySymbol,
            buyPrice: data.price,
            sellPrice: data.price,
            preBuyPrice: 1, // Set previous buy price if available
            preSellPrice: 1, // Set previous sell price if available
            createdAt: new Date(data.time_exchange).toISOString(),
            updatedAt: new Date(data.time_coinapi).toISOString(),
          };

          // Separate logic for updating cryptoCurrencies and fiat currencies
          if (
            ["BTC/USD", "ETH/USD", "BNB/USD", "USDT/USD"].includes(
              `${currencySymbol}/USD`
            )
          ) {
            // Update cryptoCurrencies state
            setcryptoCurrencies((prevCurrencies) => {
              const index = prevCurrencies.findIndex(
                (currency) => currency.name === newCurrency.name
              );

              if (index !== -1) {
                const updatedCurrencies = [...prevCurrencies];
                updatedCurrencies[index] = {
                  ...updatedCurrencies[index],
                  buyPrice: newCurrency.buyPrice,
                  sellPrice: newCurrency.sellPrice,
                  updatedAt: newCurrency.updatedAt,
                  preBuyPrice: updatedCurrencies[index].buyPrice,
                  preSellPrice: updatedCurrencies[index].sellPrice,
                };

                return updatedCurrencies;
              } else {
                return [...prevCurrencies, newCurrency];
              }
            });
          } else if (
            ["USD/TRY", "CNY/TRY", "EUR/TRY", "GBP/TRY", "AED/TRY"].includes(
              `${currencySymbol}/TRY`
            )
          ) {
            // Update fiat currencies state (e.g., USD/TRY, EUR/TRY)
            const newFiatCurrency = {
              id: 1, // Adjust ID logic as needed
              name: currencyName,
              symbol: currencySymbol,
              TRYBuyPrice: data.price, // Add the corresponding buy price for TRY
              TRYSellPrice: data.price, // Add the corresponding sell price for TRY
              TRYPreBuyPrice: 1, // Set or calculate previous TRY buy price
              TRYPreSellPrice: 1, // Set or calculate previous TRY sell price
              createdAt: new Date(data.time_exchange).toISOString(),
              updatedAt: new Date(data.time_coinapi).toISOString(),
            };

            setCurrencies((prevCurrencies) => {
              const index = prevCurrencies.findIndex(
                (currency) => currency.name === newFiatCurrency.name
              );

              if (index !== -1) {
                const updatedCurrencies = [...prevCurrencies];
                updatedCurrencies[index] = {
                  ...updatedCurrencies[index],
                  TRYBuyPrice: newFiatCurrency.TRYBuyPrice,
                  TRYSellPrice: newFiatCurrency.TRYSellPrice,
                  updatedAt: newFiatCurrency.updatedAt,
                  TRYPreBuyPrice: updatedCurrencies[index].TRYBuyPrice,
                  TRYPreSellPrice: updatedCurrencies[index].TRYSellPrice,
                };
                return updatedCurrencies;
              } else {
                return [...prevCurrencies, newFiatCurrency];
              }
            });
          }
        }
      }
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error: ", error);
    };

    return () => {
      socket.close();
    };
  }, []);

  // console.log(cryptoCurrencies);

  return (
    <div className={`App ${isToggledFromStore === false ? "darkmode" : ""}`}>
      {/* Header Section */}
      <Header />
      <Routes>
        <Route
          path="/"
          exact
          element={
            <HomePage
              cryptoCurrencies={cryptoCurrencies}
              tomanCurrencies={tomanCurrencies}
              currencies={currencies}
              golds={golds}
              coins={coins}
              noCategories={noCategories}
              remittances={remittances}
              announcements={announcements}
            />
          }
        />
        <Route
          path="/exchange-rate"
          element={
            <ExchangeRatePage
              cryptoCurrencies={cryptoCurrencies}
              tomanCurrencies={tomanCurrencies}
              currencies={currencies}
              golds={golds}
              coins={coins}
              noCategories={noCategories}
              remittances={remittances}
            />
          }
        />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
      </Routes>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default App;
