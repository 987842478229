import React from "react";
import goldss from "../assets/images/aesthetic-wallpaper-with-gold-bars.png";
import golds1080 from "../assets/images/aesthetic-wallpaper-with-gold-bars-1080.png";
import goldsAd from "../assets/images/aesthetic-wallpaper-with-gold-bars-ad.png";
import dollars from "../assets/images/american-dollars-cash-money.png";
import smartphone from "../assets/images/selective-focus-shot-male-hand-holding-smartphone.png";
import "../styles/Home.scss";

import up from "../assets/icons/up.svg";
import down from "../assets/icons/down.svg";
import diagram from "../assets/icons/diagram.svg";
import darkDiagram from "../assets/icons/dark-diagram.svg";
import Converter from "../components/Converter";
import WorldClocks from "../components/WorldClocks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// Helper function to format time to military (24-hour) format
const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });
};

// Helper function to format date as "DD MMM" without year
const formatDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-GB", { day: "numeric", month: "short" });
};

// Helper function to calculate percentage difference
const calculatePercentageDifference = (currentPrice, previousPrice) => {
  if (previousPrice === 0) return 0;
  return (((currentPrice - previousPrice) / previousPrice) * 100).toFixed(2);
};

const HomePage = ({
  currencies,
  cryptoCurrencies,
  tomanCurrencies,
  golds,
  coins,
  noCategories,
  remittances,
  announcements,
}) => {
  const isToggledFromStore = useSelector((state) => state.isToggled);

  // Find the currency with the latest `updatedAt`
  const latestCurrency = currencies?.reduce((latest, item) => {
    return new Date(item.updatedAt) > new Date(latest.updatedAt)
      ? item
      : latest;
  }, currencies[0]);

  const navigate = useNavigate();

  const handleNavigation = (selectedOption) => {
    navigate("/exchange-rate", {
      state: { selected: selectedOption, golds: golds, currencies: currencies },
    });
  };

  return (
    <div className="home">
      <div className="overlap-wrapper">
        <div className="overlap">
          {announcements && (
            <div className="gradient-border10">
              <div className="announcement">{announcements?.content}</div>
            </div>
          )}
          <div className="board-imgs">
            <div className="gradient-border3">
              <div className="prices">
                <div className="list-title">
                  <div
                    className={`title-4 ${
                      isToggledFromStore === false ? "black-scroll" : ""
                    }`}
                  >
                    {/* title-5 with the latest updated date and time */}
                    <div
                      className="g"
                      id={`${!isToggledFromStore ? "dark-title" : ""}`}
                    >
                      <div className="title-5">
                        {formatDate(latestCurrency?.updatedAt)}{" "}
                        <span className="max-[500px]:hidden">-</span>
                      </div>
                      <div className="title-5">
                        {formatTime(latestCurrency?.updatedAt)}
                      </div>
                    </div>
                    <div className="g-2">
                      <div className="title-6">Buying</div>
                      <div className="title-6">Selling</div>
                      <div className="title-6">Difference</div>
                    </div>
                  </div>
                  <div
                    className={`list-2 overflow-y-scroll h-[24rem] ${
                      isToggledFromStore === false ? "black-scroll" : ""
                    }`}
                  >
                    {/* {Array.isArray(currencies) &&
                      currencies.map((item, index) => ( */}
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7">
                          {tomanCurrencies[0]?.name}{" "}
                          <span className=""> /IRR</span>
                          <div>Ist</div>
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(tomanCurrencies[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {tomanCurrencies[0]?.istanbulBuyPrice.toLocaleString()}
                        </div>
                        <div className="text">
                          {tomanCurrencies[0]?.istanbulSellPrice.toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              tomanCurrencies[0]?.istanbulBuyPrice >
                              tomanCurrencies[0]?.istanbulPreBuyPrice
                                ? up
                                : down
                            }
                            alt={
                              tomanCurrencies[0]?.istanbulBuyPrice >
                              tomanCurrencies[0]?.istanbulPreBuyPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              tomanCurrencies[0]?.istanbulBuyPrice >
                              tomanCurrencies[0]?.istanbulPreBuyPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              tomanCurrencies[0]?.istanbulBuyPrice,
                              tomanCurrencies[0]?.istanbulPreBuyPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7">
                          {tomanCurrencies[0]?.name}{" "}
                          <span className=""> /IRR</span>
                          <div>Teh</div>
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(tomanCurrencies[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {tomanCurrencies[0]?.tehranBuyPrice.toLocaleString()}
                        </div>
                        <div className="text">
                          {tomanCurrencies[0]?.tehranSellPrice.toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              tomanCurrencies[0]?.tehranBuyPrice >
                              tomanCurrencies[0]?.tehranPreBuyPrice
                                ? up
                                : down
                            }
                            alt={
                              tomanCurrencies[0]?.tehranBuyPrice >
                              tomanCurrencies[0]?.tehranPreBuyPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              tomanCurrencies[0]?.tehranBuyPrice >
                              tomanCurrencies[0]?.tehranPreBuyPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              tomanCurrencies[0]?.tehranBuyPrice,
                              tomanCurrencies[0]?.tehranPreBuyPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7">
                          {currencies[0]?.name} <span className=""> /TRY</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(currencies[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {currencies[0]?.TRYBuyPrice.toLocaleString()}
                        </div>
                        <div className="text">
                          {currencies[0]?.TRYSellPrice.toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              currencies[0]?.TRYBuyPrice >
                              currencies[0]?.TRYPreBuyPrice
                                ? up
                                : down
                            }
                            alt={
                              currencies[0]?.TRYBuyPrice >
                              currencies[0]?.TRYPreBuyPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              currencies[0]?.TRYBuyPrice >
                              currencies[0]?.TRYPreBuyPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              currencies[0]?.TRYBuyPrice,
                              currencies[0]?.TRYPreBuyPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7">
                          {coins[0]?.name} <span className=""> /IRR</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(coins[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {coins[0]?.buyPrice.toLocaleString()}
                        </div>
                        <div className="text">
                          {coins[0]?.sellPrice.toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              coins[0]?.buyPrice > coins[0]?.preBuyPrice
                                ? up
                                : down
                            }
                            alt={
                              coins[0]?.buyPrice > coins[0]?.preBuyPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              coins[0]?.buyPrice > coins[0]?.preBuyPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              coins[0]?.buyPrice,
                              coins[0]?.preBuyPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7">
                          {golds[0]?.name} <span className=""> /USD</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(golds[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {golds[0]?.istanbulBuyPrice.toLocaleString()}
                        </div>
                        <div className="text">
                          {golds[0]?.istanbulSellPrice.toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              golds[0]?.istanbulBuyPrice >
                              golds[0]?.istanbulPreBuyPrice
                                ? up
                                : down
                            }
                            alt={
                              golds[0]?.istanbulBuyPrice >
                              golds[0]?.istanbulPreBuyPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              golds[0]?.istanbulBuyPrice >
                              golds[0]?.istanbulPreBuyPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              golds[0]?.istanbulBuyPrice,
                              golds[0]?.istanbulPreBuyPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7">
                          {noCategories[0]?.name}{" "}
                          <span className=""> /USD</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(noCategories[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {noCategories[0]?.buyPrice.toLocaleString()}
                        </div>
                        <div className="text">
                          {noCategories[0]?.sellPrice.toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              noCategories[0]?.buyPrice >
                              noCategories[0]?.preBuyPrice
                                ? up
                                : down
                            }
                            alt={
                              noCategories[0]?.buyPrice >
                              noCategories[0]?.preBuyPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              noCategories[0]?.buyPrice >
                              noCategories[0]?.preBuyPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              noCategories[0]?.buyPrice,
                              noCategories[0]?.preBuyPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>
                    <div className="item">
                      <div className="g-3">
                        <div className="title-7">
                          {cryptoCurrencies[0]?.name}{" "}
                          <span className=""> /USD</span>{" "}
                        </div>
                        {/* title-8 with formatted time */}
                        {/* <div className="title-8">
                              {formatTime(cryptoCurrencies[0].updatedAt)}
                            </div> */}
                      </div>
                      <div className="g-2">
                        <div className="text">
                          {cryptoCurrencies[0]?.buyPrice.toLocaleString()}
                        </div>
                        <div className="text">
                          {cryptoCurrencies[0]?.sellPrice.toLocaleString()}
                        </div>
                        <div className="text-icon">
                          <img
                            src={
                              cryptoCurrencies[0]?.buyPrice >
                              cryptoCurrencies[0]?.preBuyPrice
                                ? up
                                : down
                            }
                            alt={
                              cryptoCurrencies[0]?.buyPrice >
                              cryptoCurrencies[0]?.preBuyPrice
                                ? "Uptrend"
                                : "Downtrend"
                            }
                          />
                          {/* title-9 with calculated price difference */}
                          <div
                            className={`title-9 ${
                              cryptoCurrencies[0]?.buyPrice >
                              cryptoCurrencies[0]?.preBuyPrice
                                ? "green-rgba"
                                : "red-rgba"
                            }`}
                          >
                            {calculatePercentageDifference(
                              cryptoCurrencies[0]?.buyPrice,
                              cryptoCurrencies[0]?.preBuyPrice
                            )}
                            %
                          </div>
                        </div>
                      </div>
                      <img
                        className="ml-2"
                        src={!isToggledFromStore ? darkDiagram : diagram}
                        alt="Trend diagram"
                      />
                    </div>

                    {/* ))} */}
                  </div>
                </div>
                <div className="buttons">
                  <button
                    className="title-wrapper"
                    onClick={() => handleNavigation("Gold first")}
                  >
                    <div className="title-11">Gold rates</div>
                  </button>
                  <button
                    className="title-wrapper"
                    onClick={() => handleNavigation("Currency first")}
                  >
                    <div className="title-12">Currency rates</div>
                  </button>
                  <button
                    className="title-wrapper"
                    onClick={() => handleNavigation("Crypto currency first")}
                  >
                    <div className="title-13">Crypto rates</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="imgs">
              <img className="img-gold-ad" alt="Gold bars" src={goldsAd} />
              <div className="left">
                <img
                  className="img-gold-1080 hidden"
                  alt="Gold bars"
                  src={golds1080}
                />
                <img className="img-gold" alt="Gold bars" src={goldss} />
                <img
                  className="img-currency"
                  alt="American dollars"
                  src={dollars}
                />
              </div>
              <img className="img-crypto" alt="Smartphone" src={smartphone} />
            </div>
          </div>
          <Converter
            currencies={tomanCurrencies}
            golds={golds}
            crypto={cryptoCurrencies}
          />
          <WorldClocks />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
